body {
  margin: 0;
  padding: 0;
}

a:hover {
  color: #2e63f9;
}

#root{
  width: 100%;
}

.App{
  position: absolute;
  top: 0;
  min-height: 100vh;
  width: 100%;
}

.AppOuterContainer{
  position: relative;
  // min-height: calc(100vh - 14rem);
  // min-height: auto;
  // margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.AppOuterContainer > .title {
  margin: 2rem auto .25rem auto;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  h3 {
    display: block;
    margin-bottom: .75rem;
  }
}
.AppOuterContainer > .container {
  margin: 2rem auto 2rem auto;
  padding: 1.5rem;
  border-radius: 0.375rem;
  background-color: #fff;
  // BASE Variant - box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
  // SM Variant
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  width: auto;
  @media (min-width: 576px) {
   width: 100%;
  }
}

img {
  width: 100%;
}

.landing {
  position: relative;
  background-size: cover;
  background: url('../img/showcase.jpg') no-repeat center;
  height: 100vh;
  margin-top: -32px;
  margin-bottom: -56px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control::placeholder {
  color: #bbb !important;
}

.certSelect {
  z-index: 1;
  cursor: pointer;
}

.filterbox {
  border:  1px solid #c3c3c3;
  border-radius: 10px;
  padding: 10px;
}
.serach-form {
  display: inline;
}
.search-form-component {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  .certSelect {
    cursor: pointer;
  }
}

tr {
  cursor: pointer;
}

.form-check label{
  cursor: pointer;
}
.css-vj8t7z {cursor: pointer;}

.btn-group-toggle .btn-secondary {
  z-index: inherit;
}
.fontawesome-action {
  cursor: pointer;
}
.entity-card {
  cursor: unset;
}

.primary-detail {
  background: #eee;
  padding: 20px 10px;
  margin-bottom: 50px;
}
.fontawesome-action.active-icon {
  color:green;
}
.fontawesome-action.inactive-icon {
  color:red;
}

.gotoPauDau {
  color: #007bff !important;
  cursor: pointer;
}

.readOnly{
  .card-body{
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md-form{
    margin-top: 1rem;
    margin-bottom: .25rem;
  }
}

.date {
  margin-top: .5rem;

  .md-form {
    margin-top: 0;
  }
}

.col-md-1-5, .col-md-2-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-md-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-md-2-5 {
    flex: 0 0 20.083333%;
    max-width: 20.083333%;
  }
}

@media (max-width: 991px) {
  .SmallMapCol {
    min-height: 15rem
  }
}

.switch.my-auto,
.my-auto .switch {
  label {
    margin-bottom: 0;
  }
}

.modal.fade.show, .modal-backdrop.fade.show {
  z-index: 900;
}

.md-form label[data-error="Not valid"] {
  color: red;
}

.MuiDialog-root {
  z-index: 13000 !important;
}

/* for the AWS sign in screen - hides the Sign Up option */
.Section__sectionFooterSecondaryContent___Nj41Q{
  display: none;
}

.z-1000 {
  z-index: 1000 !important;
}

.cursor-default{
  cursor: default !important;
}

.NRDCustomFooter {
  background: #091a42;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    align-items: center;
  }
}
.NRDCustomNotification {
  top: 90px !important;
  right: 30px !important;
}

a.LegalLinks {
  font-size: 12px;
  color: #888 !important;
}

.card-header {
  padding-top: .3rem !important;
  padding-bottom: .3rem !important;
}

.font-weight-boldish {
  font-weight: 600;
}

.md-form.select-wrapper > label {
  top: -10px;
}

.entity.switch label {
  font-size: 16px;
}
/* Hide the tooltip by default */
.group .opacity-0 {
  display: none;
}

/* Show the tooltip on hover */
.group:hover .opacity-0 {
  display: block;
}

.tw-phone-input .PhoneInputInput {
  width: 100%;
  height: 36px;
  border-radius: 6px;
  @apply ring-1 shadow-sm ring-inset ring-gray-300 border-0 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6;

  &:disabled {
    @apply cursor-not-allowed text-gray-500 bg-gray-50;
  }
}

.tw-phone-input .PhoneInputCountry {
  display: none;
}

#jsd-widget {
  right: unset !important;
}

.esri-attribution__sources, .esri-attribution__powered-by {
  display: none;
}

.esri-ui {
  inset: 0 !important;
}